/**
 * Password Policy Implementation
 *
 * Implements a robust password validation and strength assessment system with the following features:
 * 1. Strict validation rules:
 *    - Minimum length of 8 characters
 *    - Maximum length of 48 characters
 *    - Must contain uppercase and lowercase letters and numbers
 *    - No repeating characters (3 or more)
 *    - No sequential patterns (e.g., "abc", "123")
 *    - Not in common password list
 *
 * 2. Sophisticated strength scoring:
 *    - Base score from length (up to 30 points)
 *    - Character variety bonuses (8 points each)
 *    - Multiple character type bonus (5 points per additional type)
 *    - Length bonus beyond minimum (2 points per extra character)
 *    - Penalties for patterns and common passwords
 *
 * 3. Four strength levels with increasing thresholds:
 *    - Very Weak (≤25 points)
 *    - Weak (≤45 points)
 *    - Good (≤65 points)
 *    - Strong (≤85 points)
 *
 * Note: Even if a password scores high enough for "Strong", it will be capped at "Good"
 * if it doesn't meet all validation requirements.
 */

interface StrengthLevel {
  threshold: number
  label: string
  color: string
}

interface PasswordValidationResult {
  isValid: boolean
  errors: string[]
  strengthLevel?: StrengthLevel
}

export class PasswordPolicy {
  private static readonly MIN_LENGTH = 8

  private static readonly MAX_LENGTH = 48

  private static readonly COMMON_PASSWORDS = new Set([
    'password',
    'password123',
    '123456',
    '12345678',
    'qwerty',
    'letmein',
    'admin',
    'welcome',
    'monkey',
    'football',
  ])

  private static readonly STRENGTH_LEVELS: readonly StrengthLevel[] = [
    { threshold: 25, label: 'Very Weak', color: 'red' },
    { threshold: 45, label: 'Weak', color: 'orange' },
    { threshold: 65, label: 'Good', color: 'yellow' },
    { threshold: 85, label: 'Strong', color: 'green' },
  ]

  static validate(password: string): PasswordValidationResult {
    const errors: string[] = []

    // Check length
    if (password.length < this.MIN_LENGTH) {
      errors.push(
        `Password must be at least ${this.MIN_LENGTH} characters long`
      )
    }
    if (password.length > this.MAX_LENGTH) {
      errors.push(`Password must be less than ${this.MAX_LENGTH} characters`)
    }

    // Check for common passwords
    if (this.COMMON_PASSWORDS.has(password.toLowerCase())) {
      errors.push('This password is too common and easily guessable')
    }

    // Check for character types
    if (!/[A-Z]/.test(password)) {
      errors.push('Password must contain at least one uppercase letter')
    }
    if (!/[a-z]/.test(password)) {
      errors.push('Password must contain at least one lowercase letter')
    }
    if (!/[0-9]/.test(password)) {
      errors.push('Password must contain at least one number')
    }

    // Check for repeating characters
    if (/(.)\1{2,}/.test(password)) {
      errors.push('Password cannot contain three or more repeating characters')
    }

    // Check for sequential characters (3 or more in a row)
    if (
      /(?:abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|nop|opq|pqr|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)/i.test(
        password
      )
    ) {
      errors.push(
        'Password cannot contain three or more sequential characters (e.g., abc, 123)'
      )
    }

    return {
      isValid: errors.length === 0,
      errors,
      strengthLevel: this.calculatePasswordStrength(password),
    }
  }

  private static calculatePasswordStrength(password: string): StrengthLevel {
    if (!password) {
      return this.STRENGTH_LEVELS[0] as StrengthLevel
    }

    // Base score from length (more gradual scaling)
    let score = Math.min(30, password.length)

    // Character variety checks
    const hasUpper = /[A-Z]/.test(password)
    const hasLower = /[a-z]/.test(password)
    const hasNumber = /[0-9]/.test(password)
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password)

    // Add points for character variety
    if (hasUpper) score += 8
    if (hasLower) score += 8
    if (hasNumber) score += 8
    if (hasSpecial) score += 15 // Higher bonus for special characters

    // Count variety for bonus calculations
    let varietyCount = 0
    if (hasUpper) varietyCount += 1
    if (hasLower) varietyCount += 1
    if (hasNumber) varietyCount += 1
    if (hasSpecial) varietyCount += 1

    // Extra bonus for using multiple character types
    score += (varietyCount - 1) * 5

    // Adjust length bonus based on variety
    const extraLength =
      password.length > this.MIN_LENGTH ? password.length - this.MIN_LENGTH : 0
    let lengthBonus = extraLength * 2
    if (varietyCount < 3) {
      lengthBonus = extraLength * 1 // Reduced bonus if low variety
    }
    score += lengthBonus

    // Direct penalties for missing required types
    if (!hasUpper) score -= 10
    if (!hasLower) score -= 10
    if (!hasNumber) score -= 10
    // Optionally, you can penalize the absence of special characters less or not at all

    // Penalties for common passwords, repeating, and sequential patterns
    if (this.COMMON_PASSWORDS.has(password.toLowerCase())) {
      score = Math.min(score, 10)
    }
    const repeatingChars = password.match(/(.)\1{2,}/g)
    if (repeatingChars) {
      score -= repeatingChars.length * 5
    }
    if (/(?:abc|bcd|cde|def|123|234|345)/i.test(password)) {
      score -= 10
    }

    // Cap the score for low variety passwords
    if (varietyCount < 3) {
      score = Math.min(score, 45) // Cap at "Weak"
    }

    // Get the strength level based on the final score
    const strengthLevel = this.getStrengthLevel(score)

    // If the password doesn't meet all validation requirements, cap at "Good"
    if (
      strengthLevel.label === 'Strong' &&
      (!hasUpper ||
        !hasLower ||
        !hasNumber ||
        password.length < this.MIN_LENGTH ||
        password.length > this.MAX_LENGTH ||
        this.COMMON_PASSWORDS.has(password.toLowerCase()) ||
        /(.)\1{2,}/.test(password) ||
        /(?:abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|nop|opq|pqr|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)/i.test(
          password
        ))
    ) {
      return this.STRENGTH_LEVELS[2] as StrengthLevel // Return "Good"
    }

    return strengthLevel
  }

  static getPasswordStrength(password: string): StrengthLevel {
    return this.validate(password).strengthLevel as StrengthLevel
  }

  static getRequirements(): string[] {
    return [
      `At least ${this.MIN_LENGTH} characters long`,
      'Contains at least one uppercase letter',
      'Contains at least one lowercase letter',
      'Contains at least one number',
      'No three or more repeating characters',
      'No three or more sequential characters (e.g., abc, 123)',
    ]
  }

  private static getStrengthLevel(score: number): StrengthLevel {
    // Ensure score is at least 0
    const normalizedScore = Math.max(0, score)

    // Find first level where score is less than or equal to threshold
    return (this.STRENGTH_LEVELS.find(
      (level) => normalizedScore <= level.threshold
    ) ?? this.STRENGTH_LEVELS[this.STRENGTH_LEVELS.length - 1]) as StrengthLevel
  }
}
